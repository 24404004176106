const GlimpseData=[
    
    
    {
        id:'9',
        url:'https://i.imgur.com/w1AdtzY.jpg'
    },
    {
        id:'10',
        url:'https://i.imgur.com/i71pUUK.jpg'
    },
    {
        id:'11',
        url:'https://i.imgur.com/yS9zatl.jpg'
    },
    {
        id:'12',
        url:'https://i.imgur.com/LGAQaxx.jpg'
    },
    {
        id:'13',
        url:'https://i.imgur.com/WSut03r.jpg'
    },
    {
        id:'14',
        url:'https://i.imgur.com/jO2U0nh.jpg'
    },
    {
        id:'15',
        url:'https://i.imgur.com/QpJLULr.jpg'
    },
    {
        id:'18',
        url:'https://i.imgur.com/z0ssFZp.jpg'
    },
    {
        id:'20',
        url:'https://i.imgur.com/mVtGapA.jpg'
    },
    {
        id:'21',
        url:'https://i.imgur.com/PUikQtM.jpg'
    },
    {
        id:'5',
        url:'https://i.imgur.com/FrrttdJ.png'
    },
    {
        id:'6',
        url:'https://i.imgur.com/EC1kxR2.jpg'
    },
    {
        id:'7',
        url:'https://i.imgur.com/rARUZfC.jpg'
    },
    {
        id:'8',
        url:'https://i.imgur.com/x8lmzY6.jpg'
    },
    {
        id:'1',
        url:'https://i.imgur.com/dXEizTt.jpg'
    },
    {
        id:'2',
        url:'https://i.imgur.com/a4kJWqN.jpg'
    },
    {
        id:'3',
        url:'https://i.imgur.com/R98M6j7.jpg'
    },
    {
        id:'4',
        url:'https://i.imgur.com/SQ5ANUO.jpg'
    },
    
    


];
export default GlimpseData;